import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, css, up, breakpoints } from '@xstyled/styled-components'
import Button from '../components/button'

import shapesBg from '../images/green-circle-pink-semicircle.svg'
import Text, { Emphasis } from '../components/text'
import SubHeader from '../components/sub-header'

const BgContainer = styled(Box)`
  ${up(
    'md',
    css`
      background-image: url(${shapesBg});
      background-repeat: no-repeat;
      background-position: bottom left;
    `
  )}
`

const ToolItem = styled(Col)`
  &:nth-child(n + 3) {
    ${breakpoints({
      xs: css`
        margin-top: 5;
      `,
      md: css`
        margin-top: 0;
      `,
    })}
  }

  &:nth-child(n + 4) {
    ${up(
      'md',
      css`
        margin-top: 5;
      `
    )}
  }
`

const ToolInfoBox = styled(Box)`
  background-color: primary;
  color: white;
  font-size: 1.5em;
  height: 5em;
  padding: 3;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;

  ${up(
    'md',
    css`
      height: 4em;
    `
  )}
`

const DownloadLink = styled.a`
  text-decoration: none;
`

const DownloadButton = styled(Button)`
  margin: 3 auto;
`

const StyledEmphasis = styled(Emphasis)(
  () => css`
    line-height: 1.6;
    ${breakpoints({
      xs: css`
        font-size: 20px;
      `,
      md: css`
        font-size: 22px;
      `,
      lg: css`
        font-size: 36px;
      `,
    })}
  `
)

const StartupTools = () => (
  <>
    <SEO title="Startup Tools" />
    <Layout>
      <PageHeader>Startup Tools</PageHeader>

      <BgContainer>
        <Container
          maxWidth="1000px"
          mt={{ xs: 5, md: 5, lg: 6 }}
          pb={{ xs: 2, md: '180px' }}
        >
          <StyledEmphasis
            display="block"
            textAlign="center"
            mb={{ xs: 5, md: 6 }}
          >
            We want to give founders helpful tools that helped us grow. Our library will be regularly updated.
          </StyledEmphasis>
          <Row justifyContent="center">
            <ToolItem col={{ xs: 1 / 2, md: 4 / 12 }}>
              <ToolInfoBox>Investment Pitch Deck</ToolInfoBox>
              <DownloadLink
                href={`/Horseplay_Ventures_Example_Pitch_Deck.pdf`}
                target="_blank"
              >
                <DownloadButton size="small">Download</DownloadButton>
              </DownloadLink>
            </ToolItem>
            <ToolItem col={{ xs: 1 / 2, md: 4 / 12 }}>
              <ToolInfoBox>How to impress on a pitch</ToolInfoBox>
              <DownloadLink href={`/impress.png`} target="_blank">
                <DownloadButton size="small">Download</DownloadButton>
              </DownloadLink>
            </ToolItem>
            <ToolItem col={{ xs: 1 / 2, md: 4 / 12 }}>
              <ToolInfoBox>EBITDA vs Revenue Chart</ToolInfoBox>
              <DownloadLink
                href={`/Horseplay_Revenue_Ebitda_Tracker_Example.xlsx`}
                target="_blank"
              >
                <DownloadButton size="small">Download</DownloadButton>
              </DownloadLink>
            </ToolItem>
            <ToolItem col={{ xs: 1 / 2, md: 4 / 12 }}>
              <ToolInfoBox>Investors Update</ToolInfoBox>
              <DownloadLink
                href={`/Horseplay_Investor_Update_Example.docx`}
                target="_blank"
              >
                <DownloadButton size="small">Download</DownloadButton>
              </DownloadLink>
            </ToolItem>
            <ToolItem col={{ xs: 1 / 2, md: 4 / 12 }}>
              <ToolInfoBox>Budget Model</ToolInfoBox>
              <DownloadLink
                href={`/Horseplay_Ventures_Example_Financial_Model_2020.xlsx`}
                target="_blank"
              >
                <DownloadButton size="small">Download</DownloadButton>
              </DownloadLink>
            </ToolItem>
            <ToolItem col={{ xs: 1 / 2, md: 4 / 12 }}>
              <ToolInfoBox>Great books and podcasts</ToolInfoBox>
              <DownloadLink
                href={`/Horseplay_Ventures_Reading_Listening_List.png`}
                target="_blank"
              >
                <DownloadButton size="small">Download</DownloadButton>
              </DownloadLink>
            </ToolItem>
          </Row>
          <Row justifyContent="center">
            <Col col={{ xs: 1, md: 8 / 12 }}>
              <Text
                my={{ xs: 5, md: 6 }}
                mb={{ xs: 5, md: 0 }}
                display="block"
                fontSize={{ xs: '1.4em' }}
              >
                <strong>
                  Please note: The financial downloads and Investors Update are
                  based on the DesignMyNight business model but contain dummy
                  data
                </strong>
              </Text>
            </Col>
          </Row>
        </Container>
      </BgContainer>
    </Layout>
  </>
)

export default StartupTools
